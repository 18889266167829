import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { elastic as Menu } from 'react-burger-menu'
import fbWhite from "../images/facebook-logo.svg";
import instaWhite from "../images/instagram-logo.svg";
import youtubeWhite from "../images/youtube-brands.svg";
import linkedinWhite from "../images/linkedin-in-brands.svg";
import emailWhite from "../images/email-white.svg";


import elPng from "../images/el.png"
import enPng from "../images/en.png"

import sortDownWhite from "../images/angle-down.svg"
import sortDownBlack from "../images/angle-down-solid.svg"

import Email from "../images/email.svg";
import EmailW from "../images/email-white.svg";

import Tele from "../images/phone-call.svg";
import TeleW from "../images/tele-white.svg";

import MapW from "../images/map-regular.svg"

import { StaticImage } from "gatsby-plugin-image"


import { Sticky } from 'react-sticky';


import "./MainMenu.css";

const burgerStyles = {
    bmBurgerButton: {
        display: "block",
        position: "absolute",
        width: "26px",
        height: "22px",
        left: "25px",
        top: "30px"
    },
    bmBurgerBars: {
        background: '#000'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0!important",
        left: "0"
    },
    bmMenu: {
        background: "#fff",
        padding: "0.5em 1.5em 0",
        fontSize: "1.15em",
        minWidth: "300px"
    },
    bmMorphShape: {
        fill: '#fff'
    },
    bmItemList: {
        color: "#b8b7ad",
        // padding: "0.8em"
    },
    bmItem: {
        display: "block!important",
        marginTop: "1rem",
        outline: "none"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0!important"
    },
    bmItemList: {
        overflowY: "auto"
    }
}

const burgerStyles2 = {
    bmBurgerButton: {
        display: "block",
        position: "absolute",
        width: "26px",
        height: "22px",
        right: "25px",
        top: "30px"
    },
    bmBurgerBars: {
        background: '#a53130'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: 0,
        width: "365px"

    },
    bmMenu: {
        background: "#fff",
        padding: "0.5em 1.5em 0",
        fontSize: "1.15em",
        minWidth: "365px"
    },
    bmMorphShape: {
        fill: '#fff'
    },
    bmItemList: {
        color: "#b8b7ad",
        // padding: "0.8em"
    },
    bmItem: {
        display: "block!important",
        marginTop: "1rem",
        outline: "none"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0"
    },
    bmItemList: {
        overflowY: "auto"
    }
}

function MainMenu(props) {
    const [menuState, setMenuState] = useState(false);
    const [menuState2, setMenuState2] = useState(false);

    console.log(props)

    let menu = props.headerMenu ? props.headerMenu.menuAcf.menu : [];

    const extra = props.footerMenu ? props.footerMenu.footerExtraAcf : "";

    let isMenuOpen = function (state) {
        setMenuState(state.isOpen)
    };

    let isMenuOpen2 = function (state) {
        setMenuState2(state.isOpen2)
    };

    // let nestCounter = 0;


    function createSubMenu(subs, parentLabel, distanceFromTop) {
        return (
            <ul className={`bg-white ${distanceFromTop ? "top80" : "top60"}`}>
                {subs.map((subLink, index) => (
                    <li key={`sublink-${index}`} className="flex items-center" >
                        {subLink.url && subLink.url.includes("https://")
                            ?
                            <a href={`/${subLink.url}/`} className="menu-g-link">
                                {subLink.label}
                            </a>
                            : (
                                (subLink.url && subLink.url !== "#")
                                    ?
                                    <Link to={`/${subLink.url}/`} className="menu-g-link">
                                        {subLink.label}
                                    </Link>
                                    :
                                    <a onClick={(e) => e.preventDefault()} className="menu-g-link">
                                        {subLink.label}
                                    </a>
                            )

                        }
                        {subLink.childnodes && subLink.childnodes.length > 0 && (
                            <img className="mob-sort-down h-chev-down mr-4" style={{ transform: "rotate(-90deg)" }} src={sortDownBlack} alt="sort-down" />
                        )}

                        {subLink.childnodes && subLink.childnodes.length > 0 && (
                            createSubMenu(subLink.childnodes, subLink.label, false)
                        )}
                    </li>
                ))}
            </ul>
        )
    }

    function createSubMobile(subs, parentLabel) {
        // console.log(subs)
        return (
            <ul className="pl-4 pt-2">
                {subs && subs.map((subLink, index) => (
                    <li key={`sub-mob-link-${index}`} className="flex flex-wrap items-center justify-between" >
                        {/* {console.log(subLink)} */}
                        {subLink && subLink.url.includes("https://")
                            ?
                            <a href={`/${subLink.url}/`} className="py-2">
                                {subLink.label}
                            </a>
                            :
                            <Link to={`/${subLink.url}/`} className="py-2" >
                                {subLink.label}
                            </Link>
                        }
                        {subLink.childnodes && subLink.childnodes.length > 0 && (
                            <img onClick={() => subMenuClick2(index, subLink.childnodes, subLink.label)} className="mob-sort-down h-chev-down2 mr-4" style={{ transform: "rotate(-90deg)" }} src={sortDownBlack} alt="sort-down" />
                        )}
                        {(subLink.childnodes && subLink.childnodes.length > 0) && (

                            <div className="m-dropdown-content2 hidden w-full">
                                {createSubMobile(subLink.childnodes, subLink.label)}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        )
    }

    function subMenuClick(index, t, j) {
        let isOpen = document.querySelectorAll(".m-dropdown-content")[index].style.display;

        if (isOpen === "none" || isOpen === "") {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "block";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(90deg)";
        } else {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "none";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(0deg)";
        }
    }

    function subMenuClick2(index, t, j) {
        console.log(index)

        let isOpen = document.querySelectorAll(".m-dropdown-content2")[index - 1].style.display;

        console.log(document.querySelectorAll(".m-dropdown-content2"))

        if (isOpen === "none" || isOpen === "") {
            document.querySelectorAll(".m-dropdown-content2")[index - 1].style.display = "block";
            // document.querySelectorAll(".h-chev-down2")[index].style.transform = "rotate(90deg)";
        } else {
            document.querySelectorAll(".m-dropdown-content2")[index - 1].style.display = "none";
            // document.querySelectorAll(".h-chev-down2")[index].style.transform = "rotate(0deg)";
        }
    }

    return (
        <div className="nav-container flex md:block justify-center" id="menuId">
            <div className="preheader hidden md:flex container-cs mx-auto">
                <div className="preheader hidden md:flex justify-between px-4 container m-auto">
                    <div className="flex items-center">
                        <div className='flex items-center'>
                            <span>
                                <img src={TeleW} style={{ width: "15px" }} alt="telephone" />
                            </span>
                            <span className='pl-2'>
                                {props.preHeader.telephoneNumber}
                            </span>
                            <span className='text-white pl-2'>|</span>
                        </div>

                        <div className='flex items-center pl-4'>
                            <span>
                                <img src={EmailW} style={{ width: "15px" }} alt="email" />
                            </span>
                            <span className='pl-2'>
                                {props.preHeader.supportEmail}
                            </span>
                            <span className='text-white pl-2'>|</span>
                        </div>

                        <div className='flex items-center pl-4'>
                            <span>
                                <img src={MapW} style={{ width: "15px" }} alt="map" />
                            </span>
                            <span className='pl-2'>
                                {props.preHeader.address.addressLabel.split(",")[0] + "," + props.preHeader.address.addressLabel.split(",")[2]}
                            </span>
                        </div>


                    </div>
                    <div className="flex items-center">
                        {/* <div className="pre-divider relative pr-4">
                            <span>{props.preHeader.hoursTitle} / </span>
                            <span>{props.preHeader.hoursLabel}</span>
                        </div> */}
                        <div className="flex">
                            <a className='social-container' href="https://www.facebook.com/dkaa.gr/" target="_blank" rel="noopener"><img src={fbWhite} alt="facebook-icon" style={{ width: "15px" }} /></a>
                            <a href="https://www.instagram.com/dkaa.gr/" target="_blank" rel="noopener" className="pl-2 social-container"><img src={instaWhite} alt="instagram-icon" style={{ width: "15px" }} /></a>
                            <a href="https://www.youtube.com/channel/UCl4Lkx4M3l8Qmw-tb__lCGQ" target="_blank" rel="noopener" className="pl-2 social-container"><img src={youtubeWhite} alt="youtube-icon" style={{ width: "15px" }} /></a>
                            <a href="https://www.linkedin.com/company/solidarity-net-%CE%B4%CE%AF%CE%BA%CF%84%CF%85%CE%BF-%CE%BA%CE%BF%CE%B9%CE%BD%CF%89%CE%BD%CE%B9%CE%BA%CE%AE%CF%82-%CE%B1%CE%BB%CE%BB%CE%B7%CE%BB%CE%B5%CE%B3%CE%B3%CF%8D%CE%B7%CF%82-%CE%B1%CF%81%CF%89%CE%B3%CE%AE%CF%82" target="_blank" rel="noopener" className="pl-2 social-container"><img src={linkedinWhite} alt="linkedin-icon" style={{ width: "15px", top: "0px" }} /></a>
                            <a href="mailto:info@dkaa.gr" target="_blank" rel="noopener" className="pl-2 social-container"><img src={emailWhite} alt="email-icon" style={{ width: "15px" }} /></a>

                        </div>
                    </div>
                </div>
            </div>

            <Sticky topOffset={100} style={{ width: "100%" }}>
                {({
                    style,
                    // the following are also available but unused in this example
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                }) => (
                    <div className={`m-sticky z-20 w-full ${isSticky && 'bg-white sticky-shadow'}`} style={style}>
                        <nav className="flex flex-row md:justify-between px-5 container m-auto relative container-cs mx-auto">
                            <div className="flex flex-row justify-center md:justify-between w-full md:w-auto">
                                <div id="mainMenu" className="block md:hidden"></div>
                                <Link to={props.locale === "el" ? '/' : `/${props.locale}/`} className="flex items-center relative py-2">
                                    {/* <img className="m-auto py-2" style={{ width: "178px" }} src={logoBlue} alt="white-logo" /> */}

                                    {
                                        (isSticky)
                                            ?
                                            <React.Fragment>

                                                {
                                                    props.locale === "el"
                                                        ?
                                                        <StaticImage
                                                            // className="m-auto py-2"
                                                            src="../images/logo-dkaa.png"
                                                            alt="dkaa logo"
                                                            placeholder="none"
                                                            loading={"lazy"}
                                                            quality={80}
                                                            width={180}
                                                            layout="constrained"
                                                        />
                                                        :
                                                        <StaticImage
                                                            // className="m-auto py-2"
                                                            src="../images/logo-dkaa-en.png"
                                                            alt="dkaa logo"
                                                            placeholder="none"
                                                            loading={"lazy"}
                                                            quality={80}
                                                            width={180}
                                                            layout="constrained"
                                                        />
                                                }

                                            </React.Fragment>

                                            :
                                            ((typeof window != "undefined" && window.innerWidth < 767)
                                                ?



                                                <React.Fragment>

                                                    {
                                                        props.locale === "el"
                                                            ?
                                                            <StaticImage
                                                                // className="m-auto py-2"
                                                                src="../images/logo-dkaa.png"
                                                                alt="dkaa logo"
                                                                placeholder="none"
                                                                loading={"lazy"}
                                                                quality={80}
                                                                width={180}
                                                                layout="constrained"
                                                            />
                                                            :
                                                            <StaticImage
                                                                // className="m-auto py-2"
                                                                src="../images/logo-dkaa-en.png"
                                                                alt="dkaa logo"
                                                                placeholder="none"
                                                                loading={"lazy"}
                                                                quality={80}
                                                                width={180}
                                                                layout="constrained"
                                                            />
                                                    }

                                                </React.Fragment>
                                                :
                                                <React.Fragment>

                                                    {
                                                        props.locale === "el"
                                                            ?
                                                            <StaticImage
                                                                // className="m-auto py-2"
                                                                src="../images/logo-white.png"
                                                                alt="dkaa logo"
                                                                placeholder="none"
                                                                loading={"lazy"}
                                                                quality={80}
                                                                width={180}
                                                                layout="constrained"
                                                            />
                                                            :
                                                            <StaticImage
                                                                // className="m-auto py-2"
                                                                src="../images/logo-white-en.png"
                                                                alt="dkaa logo"
                                                                placeholder="none"
                                                                loading={"lazy"}
                                                                quality={80}
                                                                width={180}
                                                                layout="constrained"
                                                            />
                                                    }

                                                </React.Fragment>
                                            )

                                    }
                                </Link>
                            </div>
                            <div className="flex md:hidden items-center text-xl" style={{position: "absolute", top: "4px", right: "25px"}}>
                                {(props.availableVersions && props.availableVersions.length > 0) && (
                                    <div className="language-switcher flex items-center pl-4">
                                        {/* {console.log(props.availableVersions)} */}

                                        {(props.currentPage && props.currentPage.currentPage === "/en/")
                                            ?
                                            <Link to={`/`} className="text-white">
                                                {props.availableVersions[0].language.name === "English"
                                                    ?
                                                    <img src={enPng} />
                                                    :
                                                    <img src={elPng} />
                                                }
                                            </Link>
                                            :
                                            <Link to={`${props.availableVersions[0].uri}`} className="text-white">
                                                {props.availableVersions[0].language.name === "English"
                                                    ?
                                                    <img src={enPng} />
                                                    :
                                                    <img src={elPng} />
                                                }
                                            </Link>
                                        }


                                    </div>
                                )}
                            </div>
                            <div className="hidden md:flex items-center text-xl">
                                <ul className={`hidden md:flex md:flex-row nav ${isSticky && 'nav-sticky'}`}>
                                    {menu.map((tab, index) => {
                                        return (
                                            <li key={`hMenu-${index}`} className="pr-5">
                                                {tab.menuGroup.url === "#"
                                                    ?
                                                    <a href={`/`}>
                                                        {tab.menuGroup.label}
                                                    </a>
                                                    :
                                                    (tab.menuGroup.url.includes("https")
                                                        ?
                                                        <a href={`${tab.menuGroup.url}/`}>
                                                            {tab.menuGroup.label}
                                                        </a>
                                                        :
                                                        <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`}>
                                                            {tab.menuGroup.label}
                                                        </Link>
                                                    )

                                                }
                                                {tab.menuGroup.childnodes && tab.menuGroup.childnodes.length > 0 && (
                                                    <img className="mob-sort-down h-chev-down" style={{ marginLeft: "0.3rem" }} src={isSticky ? sortDownBlack : sortDownWhite} alt="sort-down" />
                                                )}
                                                {tab.menuGroup.childnodes && tab.menuGroup.childnodes.length > 0 && (
                                                    createSubMenu(tab.menuGroup.childnodes, tab.menuGroup.label, true)
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                                {(props.availableVersions && props.availableVersions.length > 0) && (
                                    <div className="language-switcher flex items-center pl-4">
                                        {/* {console.log(props.availableVersions)} */}

                                        {(props.currentPage && props.currentPage.currentPage === "/en/")
                                            ?
                                            <Link to={`/`} className="text-white">
                                                {props.availableVersions[0].language.name === "English"
                                                    ?
                                                    <img src={enPng} />
                                                    :
                                                    <img src={elPng} />
                                                }
                                            </Link>
                                            :
                                            <Link to={`${props.availableVersions[0].uri}`} className="text-white">
                                                {props.availableVersions[0].language.name === "English"
                                                    ?
                                                    <img src={enPng} />
                                                    :
                                                    <img src={elPng} />
                                                }
                                            </Link>
                                        }


                                    </div>
                                )}
                                <div id="side-panel-trigger" className="side-panel-trigger pl-6" onClick={() => setMenuState2(!menuState2)}>
                                    <a href="#">
                                        <div className={`hamburger-box `}>
                                            <div className={`hamburger-inner ${isSticky && 'bg-gr'}`}></div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </nav>
                    </div>
                )}
            </Sticky>
            <Menu styles={burgerStyles} isOpen={menuState} onStateChange={isMenuOpen} className={`block md:hidden ${menuState ? "visible" : "invisible"}`} >
                <div className="pb-4">
                    <Link to={props.locale === "el" ? '/' : `${props.locale}/`}>
                        {props.locale === "el"
                            ?
                            <StaticImage
                                // className="m-auto py-2"
                                src="../images/logo-dkaa.png"
                                alt="dkaa logo"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={210}
                                layout="constrained"
                            />
                            :
                            <StaticImage
                                // className="m-auto py-2"
                                src="../images/logo-dkaa-en.png"
                                alt="dkaa logo"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={210}
                                layout="constrained"
                            />
                        }


                    </Link>
                </div>

                {menu.map((tab, index) => {
                    return (
                        <div key={`mMenu-${index}`} className="menu-item text-left" style={{ marginTop: "0.5rem", borderBottom: "1px solid gray", paddingBottom: "0.5rem" }}>

                            {tab.menuGroup.isnested
                                ?
                                <React.Fragment>
                                    <div onClick={() => subMenuClick(index, tab.menuGroup.childnodes, tab.menuGroup.label)} className="flex justify-between items-center" >
                                        <span className="h-dropdown">
                                            {tab.menuGroup.label}
                                        </span>
                                        <img className="mob-sort-down h-chev-down" src={sortDownBlack} alt="sort-down" />
                                    </div>
                                    <div className="m-dropdown-content hidden">
                                        {(tab.menuGroup.childnodes && tab.menuGroup.childnodes.length > 0) && (
                                            createSubMobile(tab.menuGroup.childnodes, tab.menuGroup.label)
                                        )}
                                    </div>
                                </React.Fragment>
                                :

                                <React.Fragment>
                                    {
                                        tab.menuGroup.url.includes("https")
                                            ?
                                            <a href={`${tab.menuGroup.url}/`}>
                                                {tab.menuGroup.label}
                                            </a>
                                            :
                                            <Link to={`/${tab.menuGroup.url.replace("http://", "")}/`}>
                                                {tab.menuGroup.label}
                                            </Link>

                                    }

                                    <div className="h-chev-down hidden"></div>
                                    <div className="m-dropdown-content hidden"></div>
                                </React.Fragment>
                            }
                        </div>
                    )
                })}


                {/* <div className="menu-item text-left text-white" style={{ "marginTop": "1rem" }}>
                    <React.Fragment>
                        <div onClick={() => subMenuClick(document.querySelectorAll(".m-dropdown-content").length - 1)} className="flex justify-between items-center" >
                            <span className="h-dropdown">
                                {props.currentPage.language}
                            </span>
                            <img className="mob-sort-down h-chev-down" src={sortDownWhite} alt="sort-down" />
                        </div>
                        <div className="m-dropdown-content hidden">
                            <ul className="pl-4 pt-4">
                                {props.availableVersions.length > 0 && props.availableVersions.map((item, index) => (
                                    <li key={`sub-mob-l-link-${index}`} className="flex" >
                                        <Link key={`alt-lang-${index}`} to={item.uri === "arxiki/" ? "/" : `${item.uri}`} className="text-white">
                                            {item.language.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </React.Fragment>
                </div> */}
            </Menu>

            <Menu styles={burgerStyles2} right isOpen={menuState2} onStateChange={isMenuOpen2} burgerButtonClassName={"my-burger-class"} itemListClassName={"r-0-i"}  >
                <div className="pb-4">
                    <Link to={props.locale === "el" ? '/' : `${props.locale}/`}>
                        {/* <img className="w-32 m-auto" style={{ width: "200px" }} src={logoWhite} alt="white-logo" /> */}
                        {props.locale === "el"
                            ?
                            <StaticImage
                                // className="m-auto py-2"
                                src="../images/logo-dkaa.png"
                                alt="dkaa logo"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={210}
                                layout="constrained"
                            />
                            :
                            <StaticImage
                                // className="m-auto py-2"
                                src="../images/logo-dkaa-en.png"
                                alt="dkaa logo"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={210}
                                layout="constrained"
                            />
                        }

                    </Link>
                    <div className='pt-8'>
                        <div className="timetable timetable-header" dangerouslySetInnerHTML={{ __html: extra.timetable }} />
                    </div>

                    <div className="pt-4">
                        <div className="pt-2 flex flex-row flex-wrap pb-8 lg:pb-0 w-full px-4  text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start relative" >
                            <div className="absolute" style={{ left: "0px" }}>
                                <img src={Tele} style={{ width: "18px" }} alt="telephone" />
                            </div>
                            <div className=" w-auto md:w-full xl:w-auto pl-4" dangerouslySetInnerHTML={{ __html: extra.telephone }} />
                        </div>
                        <div className="pt-2 flex flex-row flex-wrap pb-8 lg:pb-0 w-full px-4  text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start relative">
                            <div className="absolute" style={{ left: "0px" }}>
                                <img src={Email} style={{ width: "18px" }} alt="email" />
                            </div>
                            <div className=" w-auto md:w-full xl:w-auto pl-4" dangerouslySetInnerHTML={{ __html: extra.email }} />
                        </div>
                    </div>

                    <div className='pt-8'>
                        <div className='pb-4'>
                            <h4 style={{ color: "#222", fontSize: "21px", fontWeight: "600" }}>{props.locale === "el" ? "Χάρτης" : "Map"}</h4>
                        </div>
                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.7620549968237!2d22.934513116262764!3d40.635129179340375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a83909ecb52309%3A0xef9adb4a2992096f!2sDIKTYO%20KOINONIKIS%20ALLILENGYIS%20%26%20AROGIS!5e0!3m2!1sen!2sgr!4v1599239852386!5m2!1sen!2sgr" width="292" height="350" frameborder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabindex="0" data-uw-styling-context="true"></iframe>
                        </div>
                    </div>



                </div>

            </Menu>
        </div>
    )
}

export default MainMenu;
import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'

import Marker from "../images/placeholder.svg";
import Email from "../images/email.svg";
import Tele from "../images/phone-call.svg";
import { format } from 'date-fns'
import { el } from 'date-fns/locale'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";



import "./FooterMenu.css";


function FooterMenu(props) {

    console.log(props)
    const menu = props.footerMenu ? props.footerMenu.menuAcf.menu : [];
    const extra = props.footerMenu ? props.footerMenu.footerExtraAcf : "";

    useEffect(() => {
        if (document.querySelectorAll(".slash")[0]) {
            document.querySelectorAll(".slash")[0].style.display = "none";
        }
    }, [])

    return (
        <div>
            <div className='prefooter py-12' style={{ backgroundColor: "#f2f3f5" }}>
                <div className='flex container m-auto justify-center flex-wrap'>
                    {(extra.carouselBeforeFooter && extra.carouselBeforeFooter.carousel) && extra.carouselBeforeFooter.carousel.map((item, i) => (
                        <div key={`sp-carousel-${i}`} className='w-full md:w-1/6 pt-4 md:pt-0 sp-item'>
                            <a href={item.link} target='_blank'>
                                <img className='m-auto' src={item.image.sourceUrl} alt={item.image.altText} />
                            </a>
                        </div>
                    ))
                    }
                </div>
            </div>
            <div className="footer-container mx-auto px-5">
                <nav className=" container m-auto flex flex-wrap lg:justify-around pt-8 pb-8">
                    <div className="w-full md:w-1/4 px-4 md:px-12 fbr" >
                        {extra.postsLabel === "Τελευταία νέα"
                            ?
                            <StaticImage
                                src="../images/logo-white.png"
                                alt="dkaa logo"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={210}
                                layout="constrained"
                            />
                            :
                            <StaticImage
                                src="../images/logo-white-en.png"
                                alt="dkaa logo"
                                placeholder="none"
                                loading={"lazy"}
                                quality={100}
                                width={210}
                                layout="constrained"
                            />
                        }

                        <div className="pt-4">
                            <div className="items-center pt-2 flex pb-8 lg:pb-0 w-full text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start fbr relative" >
                                <div className="absolute" style={{ left: "-10px" }}>
                                    <img src={Marker} style={{ width: "18px" }} alt="marker" />
                                </div>
                                <div className="ml-4" style={{ maxWidth: "200px" }}>
                                    {extra.postsLabel === "Τελευταία νέα"
                                        ?
                                        <p>Πλατεία Μοριχόβου 1, ΤΚ 54625 Θεσσαλονίκη</p>
                                        :
                                        <p>Pl. Morichovou 1, Thessaloniki, 54625</p>
                                    }

                                </div>
                            </div>
                            <div className="pt-2 flex flex-row flex-wrap pb-8 lg:pb-0 w-full px-4  text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start fbr relative" >
                                <div className="absolute" style={{ left: "-10px" }}>
                                    <img src={Tele} style={{ width: "18px" }} alt="telephone" />
                                </div>
                                <div className=" w-auto md:w-full xl:w-auto" dangerouslySetInnerHTML={{ __html: extra.telephone }} />
                            </div>
                            <div className="pt-2 flex flex-row flex-wrap pb-8 lg:pb-0 w-full px-4  text-left justify-start md:justify-center md:text-center xl:text-left xl:justify-start relative">
                                <div className="absolute" style={{ left: "-10px" }}>
                                    <img src={Email} style={{ width: "18px" }} alt="email" />
                                </div>
                                <div className=" w-auto md:w-full xl:w-auto" dangerouslySetInnerHTML={{ __html: extra.email }} />
                            </div>
                        </div>
                    </div>
                    <div className="items-center w-full md:w-1/4 px-4 md:px-12 pb-8">
                        <div className="w-full f-titles">
                            {extra.postsLabel}
                        </div>
                        <div>
                            <div>
                                {props.recentPosts.nodes.length > 0 && props.recentPosts.nodes.map((rp, i) => (
                                    <Link to={"/blog/" + rp.slug} className={`flex ${props.recentPosts.nodes.length !== i + 1 ? "" : "pb-0 pt-4"}`} style={i == 0 ? ({ borderBottom: "1px solid rgba(0,0,0,.05)" }) : ({ borderBottom: "none" })} key={`related-post-${i}`}>
                                        <div className="rp-rl-bl">
                                            <div className="rp-rl-bl-h4">{rp.title}</div>
                                            <div>{format(new Date(rp.date), 'dd MMMM, yyyy', { locale: el })}</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="items-center w-full md:w-1/4 px-4 md:px-12 pb-8 pt-4 md:pt-0 fbr" >
                        <div className="flex flex-wrap">
                            <div className="w-full f-titles">
                                {extra.linksLabel}
                            </div>
                            <ul className="footer-links">
                                {menu.map((tab, index) => (
                                    <li key={`hMenu-${index}`} className="md:pr-2 xl:pr-8 ">
                                        <Link to={tab.menuGroup.url === "/" ? "/" : `/${tab.menuGroup.url}/`} >
                                            {tab.menuGroup.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="items-center w-full md:w-1/4 px-4 md:px-12 pb-8 pt-4 md:pt-0 fbr" >
                        <div className="timetable" dangerouslySetInnerHTML={{ __html: extra.timetable }} />
                    </div>

                </nav>
            </div>
            <div className="text-white py-4  w-full flex flex-wrap justify-center md:justify-between " style={{ borderTop: "1px solid rgba(0, 0, 0, 0.07)", backgroundColor: "#2a2a2a" }}>
                <div className="px-4 md:px-12 text-center md:text-left" style={{ color: "#888" }}>
                    <div dangerouslySetInnerHTML={{ __html: extra.copyrights }} />
                </div>
            </div>
        </div>
    );
}

export default FooterMenu;

export const query = graphql`fragment RecentPostsFragment on WpPostConnection {
    nodes {
      uri
      slug
      title
      date
      author {
        node {
          firstName
        }
      }
    }
}
`